import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { getMessaging, onMessage } from 'firebase/messaging';
import { BehaviorSubject } from 'rxjs';
import firebase from 'firebase/compat/app';
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: 'root'
})
export class NotificationMessagingService {

  currentMessage =  new BehaviorSubject<any>(null);

  constructor(private afMessaging: AngularFireMessaging) {

    // if (!firebase.apps.length) {
    //   firebase.initializeApp(environment.firebaseConfig);
    // }

    // const messaging = getMessaging();
    // // this.afMessaging.messages.subscribe((message) => {
    // //   console.log('Message received in NotificationMessagingService:', message);
    // //   this.currentMessage.next(message); // Emit the message to subscribers
    // // });

    // onMessage(messaging, (payload) => {
    //   console.log('Message received in NotificationMessagingService:', payload);
    //   this.currentMessage.next(payload); // Emit the message to subscribers
    // });
  }
}
