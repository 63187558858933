import { Inject, Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { SwPush } from '@angular/service-worker';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { ApiService } from "../http-server/server/api.service";
import { BehaviorSubject, Subscription } from 'rxjs';
import { LocalStorageService } from "./local-storage.service";
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import firebase from 'firebase/compat/app';
import { Platform } from '@angular/cdk/platform';
import { ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
  PermissionStatus } from '@capacitor/push-notifications';

import { Capacitor} from '@capacitor/core';
import { Toast } from '@capacitor/toast';
import { LocalNotifications } from '@capacitor/local-notifications';

//import { App } from '@capacitor/app';
//import { App } from '@capacitor/app';
import { App } from '@capacitor/app';
import { Dialog } from '@capacitor/dialog';
import { Router } from '@angular/router';
import { NgZone } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { AppLauncher } from '@capacitor/app-launcher';
import { NativeSettings, AndroidSettings, IOSSettings } from 'capacitor-native-settings';

@Injectable({
  providedIn: 'root'
})

//@Injectable()
export class FirebaseNotificationService {

  //private messaging: firebase.messaging.Messaging;

  private subscriptions: Subscription[] = [];
  currentMessage = new BehaviorSubject<any>(null);
 
  constructor(private api: ApiService, private platform : Platform, private storage: LocalStorageService, private router: Router, private zone: NgZone) {}

  init() {
    // console.log('Initializing HomePage');
    // Toast.show({
    //   text: 'Push registration success, token: ',
    //   duration: 'long',
    // });

    // if (Capacitor.isPluginAvailable('PushNotifications')) {
    //   PushNotifications.register();
    // } else if (Capacitor.getPlatform() === 'web') {
    //     console.log('Push notifications are not supported on the web.');
    //     return;
    // } else {
    //   console.warn('PushNotifications plugin is not available on this platform.');
    // }

    //alert(Capacitor.getPlatform());
    if (Capacitor.getPlatform() === 'web') {
        console.log('Push notifications are not supported on the web.');
        return;
    } else {
      this.registerPush();
    }
  }
    
  private registerPush() {
    //alert("registerPush calling");
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        //alert("show Open app settings :: ");
        // Show some error
        this.openAppSettings();
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {

        this.sendTokenToServer(token.value);
        // Toast.show({
        //   text: 'Push registration success, token: ' + token.value + " :: token :: " + token,
        //   duration: 'long',
        // });
        //alert('Push registration success, token: ' + token.value);

      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        //alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));

        // Toast.show({
        //   text: 'Push received:: ' + JSON.stringify(notification),
        //   duration: 'long',
        // });

         // Display the notification using Local Notifications
         PushNotifications.addListener('pushNotificationReceived', (notification) => {
          LocalNotifications.schedule({
            notifications: [
              {
                title: notification.title,
                body: notification.body,
                id: 1,
                extra: notification.data
              }
            ]
          });
        });
     
  });

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (action: ActionPerformed) => {
        console.log('🔔 Push Action Performed:', action);
        //alert('Push action performed: ');
        //alert(action.notification?.data?.url);
        //alert('Running in Capacitor:'+ Capacitor.isNativePlatform());
        //alert('Frontend URL:'+ location.origin);
        //alert("window.location.href" + window.location.href);
        // const protocol = window.location.protocol; // "https:" or "http:"
        // const host = window.location.host; // e.g., "localhost:4200" or "stag-app.yesconnect.in"
        // const pathname = window.location.pathname; // e.g., "/menu/yes-pledge"
        // const search = window.location.search; // e.g., "?id=123"
        // const hash = window.location.hash; // e.g., "#section"

        // const fullUrl = `${protocol}//${host}${pathname}${search}${hash}`;
        //alert(fullUrl);
        //alert('Push action performed: ' + JSON.stringify(notification));

        //Redirect based on notification data
        const redirectUrl = action.notification?.data?.url; // Customize your key
        //alert(redirectUrl);
        // if (redirectUrl) {
        //   //this.router.navigateByUrl(redirectUrl);
        //   this.router.navigate([redirectUrl]);
        // }
        if (redirectUrl.startsWith('http') || redirectUrl.startsWith('https')) {
          window.location.href = redirectUrl;  // External URL
        } else {
          this.zone.run(() => {
            this.router.navigate([redirectUrl]);  // Internal Angular route
          });

        }
      }
    );
  }

  // openAppSettings() {
  //   if ((window as any).Capacitor) {
  //     (window as any).Capacitor.Plugins.App.openAppSettings();
  //   } else {
  //     console.log("Capacitor not available, open settings manually");
  //   }
  // }

  async openAppSettings() {

    if (Capacitor.getPlatform() === 'android') {
      const permission: PermissionStatus = await PushNotifications.checkPermissions();
      //alert("permission.receive :: " + permission.receive);
      // if (permission.receive === 'granted') {

      // } else {
        NativeSettings.openAndroid({
          option: AndroidSettings.AppNotification,
        });
      //}
        
    } else {
      console.log("Capacitor not available, open settings manually");
    }

    // const confirm = await Dialog.confirm({
    //   title: 'Enable Notifications',
    //   message: 'Please enable notifications in the app settings to receive updates.',
    //   okButtonTitle: 'Open Settings',
    //   cancelButtonTitle: 'Cancel',
    // });

    // if (confirm.value) {
    //   if ((window as any).Capacitor && (window as any).Capacitor.Plugins) {

    //     if (Capacitor.getPlatform() === 'android') {
    //       NativeSettings.openAndroid({
    //         option: AndroidSettings.AppNotification,
    //       });
    //     }
        
    //   } else {
    //     console.log("Capacitor not available, open settings manually");
    //   }
    // }
  }

  private getUserId(): string {
    // Implement your logic to retrieve the user's ID, e.g., from a user service or storage
    return this.storage.getItem('user_id') || 0; // Replace with actual user ID retrieval logic
  }
 private async sendTokenToServer(token) {
    // console.log("sendTokenToServer :: ");
    // TODO: Send the token to your server
    // console.log('Token sent to server:', token);
    const userId = this.getUserId(); // Replace with actual method to get user ID
    const current_token = token; //typeof token === 'string' ? token : token.endpoint;
    const act_token = token; //this.extractToken(current_token);
    let platformDevice = ''
    if(this.platform.ANDROID){
      platformDevice = 'ANDROID'
    } else if(this.platform.IOS){
      platformDevice = 'IOS'
    } else if(this.platform.FIREFOX){
      platformDevice = 'FIREFOX'
    } else if(this.platform.EDGE){
      platformDevice = 'EDGE'
    } else if(this.platform.SAFARI){
      platformDevice = 'SAFARI'
    } else if(this.platform.TRIDENT){
      platformDevice = 'TRIDENT'
    } else if(this.platform.BLINK){
      platformDevice = 'BLINK'
    } else if(this.platform.WEBKIT){
      platformDevice = 'WEBKIT'
    }
    const payload = {
      user_id: (userId && Number(userId) > 0) ? parseInt(userId) : 0,
      account_id: this.storage.getItem('account_id') || 0,
      reference_id: this.storage.getItem('reference_id') || 0,
      reference_object: this.storage.getItem('reference_object') || '',
      //token: typeof token === 'string' ? token : token.endpoint, // Handle string or PushSubscription
      token: act_token,
      token_detail: current_token,
      platform : platformDevice || ''
      // platform: 'web',
      // device_type: 'web',
      // device_id: '', // Replace with actual device ID
      // app_version: '1.0.0',
      // device_model: 'Chrome',
      // os_version: '80.0.3987.165' // Replace with actual device OS version
    };
    console.log('Payload::::::::::::::::::::::::::::', payload);

    // Toast.show({
    //   text: 'Push registration success, payload: ' + payload,
    //   duration: 'long',
    // });

    const apiSubscription = await this.api.gspApiPostCallMethod(payload, 'addFCMPushNotificationToken').subscribe((res: any) => {
      console.log(res);
      if (res.status) {
        
      } else {

      }
    });
  this.subscriptions.push(apiSubscription);
    
    // this.http.post(this.apiUrl, payload).subscribe({
    //   next: (response) => {
    //     console.log('Token saved successfully:', response);
    //   },
    //   error: (error) => {
    //     console.error('Failed to save token to server:', error);
    //   }
    // });
    
  }
}// //  constructor(readonly swPush: SwPush, private api: ApiService, private storage: LocalStorageService) { 
//   constructor(private api: ApiService, private storage: LocalStorageService, private afMessaging: AngularFireMessaging, 
//     private platform : Platform) { 
//     //this.registerServiceWorker();
//     this.listenForMessages();
//     // Initialize Firebase if not already done
//     if (!firebase.apps.length) {
//       firebase.initializeApp(environment.firebaseConfig);
//     }

//   }


  // init() {
  //   // Perform any required initialization here
  //   console.log('FirebaseNotificationService initialized');
  //   this.registerServiceWorker();
  // }

  // registerServiceWorker() {

  //   if (!('serviceWorker' in navigator)) {
  //     console.log('Service Worker is not supported on this browser.');
  //     return;
  //   }

  //   if (!('PushManager' in window)) {
  //     console.log(`PushManager is not supported in this browser.`);
  //     return;
  //   } 
    
  //   // navigator.serviceWorker.getRegistrations().then((registrations) => {
  //   //     registrations.forEach((registration) => {
  //   //       registration.unregister();
  //   //     });
  //   //   }).then(() => {

  //       //navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/firebase/' })
  //       navigator.serviceWorker.register('firebase-messaging-sw.js')
  //       //navigator.serviceWorker.register('/firebase-sw.js', { scope: '/' })
  //         .then((registration) => {

  //           console.log('Service Worker registered with scope:', registration.scope);

  //           this.checkSubscriptionStatus(registration);

  //         })
  //         .catch((err) => {
  //           console.error('Service Worker registration failed:', err);
  //         });

  //   //});
          
  // }

  // private checkSubscriptionStatus(registration: ServiceWorkerRegistration) {
  //   console.log("checkSubscriptionStatus :: ");
  //   //registration.pushManager.getSubscription()
  //   registration.pushManager.subscribe({
  //     userVisibleOnly: true,
  //     applicationServerKey: this.urlB64ToUint8Array(environment.fcm_vapid_key)
  //   })
  //     .then(subscription => {
  //       if (subscription) {

          
  //         // subscription.unsubscribe().then(() => {
  //         //  console.log("user unsubscripted :::::: " , subscription);
  //         // });
  //         // console.log('User is already subscribed:', subscription);
  //         // Optionally send the existing subscription to your server
  //         //this.sendTokenToServer(subscription); // This payload token not working
  //         this.subscribeToPushNotifications(registration);
  //       } else {
  //         console.log('User is not subscribed. Subscribing now...');
  //         //this.requestPermissionAndSubscribe(registration);
  //         this.checkNotificationPermission(registration);
  //       }
  //     })
  //     .catch(err => {
  //       console.error('Failed to get subscription status:', err);
  //     });
  // }

  // private checkNotificationPermission(registration: ServiceWorkerRegistration) {
  //   console.log("checkNotificationPermission :: ");
  //   if (Notification.permission === 'granted') {
  //     console.log("Permission granted");
  //     console.log('Notifications permission already granted. ->', Notification.permission);
  //     this.subscribeToPushNotifications(registration);
  //   } else if (Notification.permission === 'denied') {
  //     console.log("Permission denied");
  //     console.warn('Notifications permission denied. Requesting permission...-> ' + Notification.permission);
  //     console.warn('Notifications are blocked. Please enable them in your browser settings.');
  //   } else {
  //     console.log("Request Permission");
  //     Notification.requestPermission().then(permission => {
  //       if (permission === 'granted') {
  //         console.log('Notifications permission already granted. ->', Notification.permission);
  //         this.subscribeToPushNotifications(registration);
  //       } else {
  //         console.warn('Push notifications permission was not granted.');
  //       }
  //     }).catch(err => {
  //       console.error('Failed to request notification permission:', err);
  //     });
  //   }
  // }

  // private subscribeToPushNotifications(registration: ServiceWorkerRegistration) {
  //   // console.log("subscribeToPushNotifications :: ");
  //   const messaging = getMessaging();

  //   getToken(messaging, { vapidKey: environment.fcm_vapid_key, serviceWorkerRegistration: registration })
  //     .then((currentToken) => {
  //       if (currentToken) {
  //         // console.log('Device token:', currentToken);

  //         //this.deleteTokenFromServer(currentToken);
  //          // Send the token to your server for future use
  //         this.sendTokenToServer(currentToken);
         
  //       } else {
  //         console.warn('No registration token available. Request permission to generate one.');
  //       }
  //     })
  //     .catch((err) => {
  //       console.error('An error occurred while retrieving token. ', err);
  //     });
  // }

  // private async deleteTokenFromServer(token: string | PushSubscription) {
              
  //             const userId = this.getUserId(); // Replace with actual method to get user ID
  //             const current_token = typeof token === 'string' ? token : token.endpoint;
  //             const act_token = this.extractToken(current_token);

  //             let payload = {
  //               token : act_token,
  //               userId: userId
  //             };

  //             const apiSubscription = await this.api.gspApiPostCallMethod(payload, 'deleteFCMPushNotificationToken').subscribe((res: any) => {
  //               console.log(res);
  //               //if (res.status) {

                  
  //                 console.log("res status :: ", res.status);
  //               // } else {
          
  //               // }
  //             });
  // }

  // private async sendTokenToServer(token: string | PushSubscription) {
  //   // console.log("sendTokenToServer :: ");
  //   // TODO: Send the token to your server
  //   // console.log('Token sent to server:', token);
  //   const userId = this.getUserId(); // Replace with actual method to get user ID
  //   const current_token = typeof token === 'string' ? token : token.endpoint;
  //   const act_token = this.extractToken(current_token);
  //   let platformDevice = ''
  //   if(this.platform.ANDROID){
  //     platformDevice = 'ANDROID'
  //   } else if(this.platform.IOS){
  //     platformDevice = 'IOS'
  //   } else if(this.platform.FIREFOX){
  //     platformDevice = 'FIREFOX'
  //   } else if(this.platform.EDGE){
  //     platformDevice = 'EDGE'
  //   } else if(this.platform.SAFARI){
  //     platformDevice = 'SAFARI'
  //   } else if(this.platform.TRIDENT){
  //     platformDevice = 'TRIDENT'
  //   } else if(this.platform.BLINK){
  //     platformDevice = 'BLINK'
  //   } else if(this.platform.WEBKIT){
  //     platformDevice = 'WEBKIT'
  //   }
  //   const payload = {
  //     user_id: (userId && Number(userId) > 0) ? parseInt(userId) : 0,
  //     account_id: this.storage.getItem('account_id') || 0,
  //     reference_id: this.storage.getItem('reference_id') || 0,
  //     reference_object: this.storage.getItem('reference_object') || '',
  //     //token: typeof token === 'string' ? token : token.endpoint, // Handle string or PushSubscription
  //     token: act_token,
  //     token_detail: current_token,
  //     platform : platformDevice || ''
  //     // platform: 'web',
  //     // device_type: 'web',
  //     // device_id: '', // Replace with actual device ID
  //     // app_version: '1.0.0',
  //     // device_model: 'Chrome',
  //     // os_version: '80.0.3987.165' // Replace with actual device OS version
  //   };
  //   console.log('Payload::::::::::::::::::::::::::::', payload);

  //   const apiSubscription = await this.api.gspApiPostCallMethod(payload, 'addFCMPushNotificationToken').subscribe((res: any) => {
  //     console.log(res);
  //     if (res.status) {
        
  //     } else {

  //     }
  //   });
  // this.subscriptions.push(apiSubscription);
    
  //   // this.http.post(this.apiUrl, payload).subscribe({
  //   //   next: (response) => {
  //   //     console.log('Token saved successfully:', response);
  //   //   },
  //   //   error: (error) => {
  //   //     console.error('Failed to save token to server:', error);
  //   //   }
  //   // });
    
  // }


  // private listenForMessages() {
    
  //   //     // if (message.notification && Notification.permission === 'granted') {
  //   //     //   const notificationTitle = message.data.title;
  //   //     //   const notificationOptions = {
  //   //     //     body: message.data.body,
  //   //     //     icon: message.data?.icon || 'https://app.yesconnect.in/assets/media/svg/yes-icons/yes-logo.svg',
  //   //     //     data: {
  //   //     //       url: message.data?.url || message.fcmOptions?.link // Add URL to be opened on click
  //   //     //     }
  //   //     //   };
    
  //   //     //   // Trigger the notification manually
  //   //     //   const notification = new Notification(notificationTitle, notificationOptions);
    
  //   //     //   // Handle notification click
  //   //     //   notification.onclick = (event) => {
  //   //     //     window.open(notificationOptions.data.url, '_blank');
  //   //     //   };
  //   //     // }
  //   //     // Handle the message as necessary, e.g., navigate based on URL
  //   //     // if (message['data'] && message['data'].url) {
  //   //     //   window.open(message['data'].url, '_blank'); // Open the URL in a new tab
  //   //     // }
  //   //   },
  //   //   (error) => {
  //   //     console.error('Error receiving message:', error);
  //   //   }
  //   // );

  //   // this.subscriptions.push(messageSubscription);
  // }

  // private getUserId(): string {
  //   // Implement your logic to retrieve the user's ID, e.g., from a user service or storage
  //   return this.storage.getItem('user_id') || 0; // Replace with actual user ID retrieval logic
  // }

  // public unsubscribe() {
  //   if (this.subscriptions) {
  //     this.subscriptions.forEach(d => d.unsubscribe());
  //   }
  // }

  

  // extractToken(input) {
  //   // Define the regex pattern to match the token in the URL
  //   //const regex = /(?:https:\/\/fcm\.googleapis\.com\/fcm\/send\/)?([\w-]+:[\w-]+)/;
  //   const regex = /(?:https:\/\/fcm\.googleapis\.com\/fcm\/send\/)?([\w-]+:[\w-]+[A-Za-z0-9_-]+)/;
    
  //   // Apply the regex pattern to the input
  //   const match = input.match(regex);
    
  //   // If there's a match, return the token (group 1)
  //   return match ? match[1] : null;
  // }

  // urlB64ToUint8Array(base64String) {
  //   const padding = '='.repeat((4 - base64String.length % 4) % 4);
  //   const base64 = (base64String + padding)
  //     .replace(/-/g, '+')
  //     .replace(/_/g, '/');
  
  //   const rawData = window.atob(base64);
  //   const outputArray = new Uint8Array(rawData.length);
  
  //   for (let i = 0; i < rawData.length; ++i) {
  //     outputArray[i] = rawData.charCodeAt(i);
  //   }
  
  //   return outputArray;
  // }


//}
