import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPullToRefresh]',
 // standalone: true
})
export class PullToRefreshDirective {

  constructor(private el: ElementRef) {}

  touchStartY = 0;

  get scrollContainer(): HTMLElement {
    return this.el.nativeElement;
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.touchStartY = event.touches[0].clientY;
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    const touchEndY = event.changedTouches[0].clientY;
    const swipeDistance = touchEndY - this.touchStartY;

    // Ensure user pulled down & container is scrolled to the top
    if (swipeDistance > 100 && this.scrollContainer.scrollTop === 0) {
      console.log('Pull-to-refresh triggered');
      window.location.reload();
    }
  }

  // @HostListener('window:scroll', ['$event'])
  // onScroll(event: any) {
  //   //alert("onScroll :: ");
  //   if (window.scrollY <= 0) {
  //     location.reload(); // Reload when pulled down
  //   }
  // }

  // @HostListener('touchstart', ['$event'])
  // onTouchStart(event: TouchEvent) {
  //   //alert("onTouchStart :: ");
  //   this.touchStartY = event.touches[0].clientY;
  // }

  // @HostListener('touchend', ['$event'])
  // onTouchEnd(event: TouchEvent) {
  //   alert("onTouchEnd :: ");
  //   const touchEndY = event.changedTouches[0].clientY;
  //   const swipeDistance = touchEndY - this.touchStartY;

  //   // Trigger refresh if swipe-down is more than 100px and the page is at the top
  //   // if (swipeDistance > 100 && this.el.nativeElement.scrollTop === 0) {
  //   //   console.log('Pull-to-refresh triggered');
  //   //   window.location.reload();
  //   // }

  //   if (
  //     swipeDistance > 100 &&
  //     (document.documentElement.scrollTop === 0 || document.body.scrollTop === 0)
  //   ) {
  //     console.log('Pull-to-refresh triggered');
  //     window.location.reload();
  //   }
  // }


  //--------------------------------------------------

  // @HostListener('touchend', ['$event'])
  // onTouchEnd(event: TouchEvent) {
  //   const touchEndY = event.changedTouches[0].clientY;
  //   if (touchEndY - this.touchStartY > 100) {
  //     // User swiped down more than 100px → Reload the app
  //     console.log('Global pull-to-refresh triggered');
  //     window.location.reload();
  //   }
  // }

}
