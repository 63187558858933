import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appSwipeToRefresh]',
  standalone: true,
})
export class SwipeToRefreshDirective {
  // private startY: number = 0;
  // private isRefreshing: boolean = false;

  // @Output() refreshTriggered = new EventEmitter<void>();

  // constructor(private el: ElementRef) {}

  // @HostListener('touchstart', ['$event'])
  // onTouchStart(event: TouchEvent) {
  //   //alert("onTouchStart :: :: ");
  //   this.startY = event.touches[0].clientY;
  // }

  // @HostListener('touchmove', ['$event'])
  // onTouchMove(event: TouchEvent) {
  //   //alert("onTouchMove :: :: ");
  //   const currentY = event.touches[0].clientY;
  //   const scrollTop = this.el.nativeElement.scrollTop;

  //   // Detect pull-down gesture at top of the page
  //   if (scrollTop === 0 && currentY - this.startY > 50 && !this.isRefreshing) {
  //     this.isRefreshing = true;
  //     this.refreshTriggered.emit();
  //   }
  // }

  // @HostListener('touchend')
  // onTouchEnd() {
  //   this.isRefreshing = false;
  // }

  @Output() refreshTriggered = new EventEmitter<void>();
  
  private startY = 0;
  private isSwiping = false;

  constructor(private el: ElementRef) {}

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    if (window.scrollY === 0) { // ✅ Only trigger if at the top of the page
      this.startY = event.touches[0].clientY;
      this.isSwiping = true;
      //this.refreshTriggered.emit();
    }
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    if (!this.isSwiping) return;

    const moveY = event.touches[0].clientY - this.startY;

    // if (moveY > 50) {  // ✅ Allow swipe effect after 50px
    //   event.preventDefault(); // 🚀 Prevent browser pull-to-refresh
    // }

    if (moveY > 80) { // ✅ Detects a downward swipe of at least 80px
      this.refreshTriggered.emit();
      this.isSwiping = false;
    }
  }

  @HostListener('touchend')
  onTouchEnd() {
    this.isSwiping = false;
  }
}
