import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, RouterOutlet } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { AgAuthService, AclService, AgAuthGuard } from './ag-core/_agaram/ag-core';
import { AgLoginGuard } from './ag-core/_agaram/ag-core/guards/ag-login.guard';
import { NavigationService } from './ag-core/_agaram/ag-shared/services/navigation.service';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from '../environments/environment';
import { FirebaseNotificationService } from './ag-core/_agaram/ag-core/services/firebase-notification.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics'
import { PullToRefreshDirective } from './ag-core/_agaram/ag-core/directives/pull-to-refresh.directive';

registerLocaleData(en);

const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
  };
  const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

function appInitializer(authService: AgAuthService, aclService: AclService) {
  return () => {
  return new Promise(async (resolve) => {
    console.log("appInitializer :: ");

    // Check if service workers are supported
    // if ('serviceWorker' in navigator && environment.production) {
    //   navigator.serviceWorker.register('ngsw-worker.js').then(
    //     (registration) => {
    //       console.log('Service Worker registered successfully:', registration);
  
    //       if (registration.installing) {
    //         registration.installing.onstatechange = () => {
    //           console.log('Service Worker state changed:', registration.installing?.state);
    //         };
    //       }
  
    //     },
    //     (error) => {
    //       console.error('Service Worker registration failed:');
    //       console.error('Message:', error.message);
    //       console.error('Stack:', error.stack);
    //       console.error('Details:', error);
    //     }
    //   );

    //    // Register the custom service worker
    //   navigator.serviceWorker
    //   .register('custom-sw.js')
    //   .then((registration) => {
    //     console.log('[Custom Service Worker] Registered:', registration);
    //   })
    //   .catch((error) => {
    //     console.error('[Custom Service Worker] Registration failed:', error);
    //   });
    // }
    
    


    //firebaseNotificationService.registerServiceWorker();
    localStorage.removeItem('lead_mobile_list_filter');
    if (localStorage.getItem('authToken')) {
      //firebaseNotificationService.init();
      let is_volunteer_login = localStorage.getItem('is_volunteer_login');
      let is_yescon_volunteer_login = localStorage.getItem('is_yescon_volunteer_login');;
      let isVolunteerLogin = is_volunteer_login == 'true' ? true : false;
      let isYesconVolunteerLogin = is_yescon_volunteer_login == 'true' ? true : false;
      // console.log('isVolunteerLogin------------------------------>', isVolunteerLogin);
      authService.getLoginDetailsByReference();
      if(isVolunteerLogin) {
        await authService.getEventVolunteerDetailsbyMobileNumber();
        let permissions = localStorage.getItem('volunteer_access_permissions');
        permissions = JSON.parse(permissions);
        return authService.loadVolunteerLoginPermissions(permissions).then(
          (res) => {
            if (res) {
              aclService.setPermissions();
              resolve(true);
            } else {
              resolve(false);
            }
          }
        );
      } else if(isYesconVolunteerLogin) {
          await authService.getYesconEventVolunteerDetailsbyMobileNumber();
          let permissions = localStorage.getItem('volunteer_access_permissions');
          permissions = JSON.parse(permissions);
          return authService.loadVolunteerLoginPermissions(permissions).then(
            (res) => {
              if (res) {
                aclService.setPermissions();
                resolve(true);
              } else {
                resolve(false);
              }
            }
          );
      } else {
        return authService.loadPermissions().then(
          async (res) => {
            if (res) {
              await authService.getEventVolunteerDetailsbyMobileNumber();
              let volunteerAccessPermissions = localStorage.getItem('volunteer_access_permissions');
              volunteerAccessPermissions = JSON.parse(volunteerAccessPermissions);
              authService._PERMISSIONS = authService._PERMISSIONS.concat(volunteerAccessPermissions);
              // console.log('authService._PERMISSIONS---------------------------->', authService._PERMISSIONS);
              aclService.setPermissions();
              resolve(true);
            } else {
              resolve(false);
            }
          }
        );
      }
    } else {
      resolve(true);
    }
    // if(navigator.onLine) {
    // }
  });
};
}

export const AppProviders = [
  importProvidersFrom(
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    RouterOutlet,
    HttpClientModule,
    ClipboardModule,
    NgbModule,
    AgLoginGuard,
    AgAuthGuard,
    TranslateModule.forRoot(),
    InlineSVGModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.MEASUREMENT_ID),
    NgxGoogleAnalyticsRouterModule,
    
    // ServiceWorkerModule.register('ngsw-worker.js', { 
    //   enabled : environment.production,
    //   registrationStrategy : 'registerWhenStable:30000' 
    // }),
    // ServiceWorkerModule.register('ngsw-worker.js', { 
    //   enabled : environment.production,
    //   registrationStrategy : 'registerImmediately' 
    // }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled : environment.production 
    }),
    // ServiceWorkerModule.register('firebase-messaging-sw.js', {
    //   enabled : environment.production 
    // }),

    AngularFireModule.initializeApp(environment.firebaseConfig),
    
    AngularFireMessagingModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      closeButton: true,
      progressBar: true,
      toastClass: 'font-roboto ngx-toastr',
      progressAnimation: 'decreasing',
      preventDuplicates: true
    })
  ),
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializer,
    multi: true,
    deps: [AgAuthService, AclService],
  },
  {
    provide: NZ_I18N,
    useValue: en_US
  },
  {
    provide: NZ_ICONS,
    useValue: icons
  },
  NavigationService,
  FirebaseNotificationService,
  PullToRefreshDirective
];