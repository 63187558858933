<!-- <div class="scroll-container" appPullToRefresh>
    <router-outlet></router-outlet>
</div> -->
<!-- <div class="app-container" appSwipeToRefresh (refreshTriggered)="refreshData()"> -->
  <!-- <div appSwipeToRefresh (refreshTriggered)="refreshData()"> -->
    <!-- <div class="refresh-indicator" *ngIf="isRefreshing">🔄 Refreshing...</div> -->
    <router-outlet></router-outlet>
  <!-- </div> -->
    <!-- <div *ngIf="isRefreshing" class="loader-overlay">
        <div class="loader"></div>
      </div>
  </div> -->
  <!-- <div *ngIf="isRefreshing" class="refresh-loader">
    <div class="spinner"></div>
  </div> -->

<!-- <router-outlet appPullToRefresh></router-outlet> -->

<app-ag-utils></app-ag-utils>
