import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { catchError } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { ApiService } from "../http-server/server/api.service";
import { Platform } from '@angular/cdk/platform';
import { LocalStorageService } from "./local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  //private infoLogUrl = '/api/logs/info';  // Backend info log API
  //private errorLogUrl = '/api/logs/error';  // Backend error log API

  constructor(private api: ApiService, @Inject(DOCUMENT) private document: Document, private platform : Platform, private storage: LocalStorageService,) {}

  logLevel: any = { info: 'info', warn: 'warn', error: 'error' }; 
  private subscriptions: Subscription[] = [];

  private getUserAgent(): string {
    return this.document.defaultView?.navigator.userAgent || 'unknown';
  }

  info(message: string, ...optionalParams: any[]): void {
    this.sendLog(this.logLevel.info, message, optionalParams);
  }

  error(message: string, ...optionalParams: any[]): void {
    this.sendLog(this.logLevel.error, message, optionalParams);
  }

  warn(message: string, ...optionalParams: any[]): void {
    this.sendLog(this.logLevel.warn, message, optionalParams);
  }

  private async sendLog(level: string, message: string, optionalParams: any[] ): Promise<void> {
    //console.log("sendLog :: ");
    const userAgent = this.getUserAgent();
    const platformName = this.getPlatform();

    // switch (level) {
    //   case this.logLevel.info:
    //     console.info(message);
    //     break;
    //   case this.logLevel.warn:
    //     console.warn(message);
    //     break;
    //   case this.logLevel.error:
    //     console.error(message);
    //     break;
    //   default:
    //     console.debug(message);
    // }

    let payload = {
      userAgent: userAgent,
      message: message,
      optionalParams: optionalParams,
      level: level,
      platform: platformName,
      account_id: this.storage.getItem('account_id') || 0,
    }

    let auth_token = this.storage.getItem('authToken') || '';
    
    let is_auth = false;
    let path= 'logs';
    if(auth_token) {
      is_auth = true;
      path = `logs/logs`;
    }
    
    // const apiSubscription = await this.api.postCallMethod(payload, path, is_auth).subscribe((res: any) => {
    //   //console.log(res);
    //   if (res.status) {
        
    //   } else {

    //   }
    // });

    //this.subscriptions.push(apiSubscription)

  }

  ngOnDestroy() {
    this.subscriptions.forEach(d => d.unsubscribe());
  }

  private formatLogMessage(level: string, message: string): string {
    const platformName = this.getPlatform();
    const userAgent = navigator.userAgent;
    const timestamp = new Date().toLocaleString();

    return `${timestamp} - ${level}: [Angular UX] [Platform: ${platformName}] [User-Agent: ${userAgent}] ${message}`;
  }

  private getPlatform(): string {
    //console.log("this.platform :: ", this.platform);
    if (this.platform.ANDROID) {
      return 'Android';
    } else if (this.platform.IOS) {
      return 'iOS';
    } else {
      return 'Web';
    }
  }

  // private getLogLevel(level: number): string {
  //   switch (level) {
  //     case this.logLevel.info: return 'info';
  //     case this.logLevel.Warn: return 'warn';
  //     case this.logLevel.Error: return 'error';
  //     default: return 'debug';
  //   }
  // }

  
}
